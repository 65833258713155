<template>
  <q-dialog ref="dialog" persistent @hide="hide" class="edit-term-dialog">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/add-term.svg" class="dialog-header-icon" alt="">
        {{ dialogTitle }}
      </div>

      <q-banner v-if="error" class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <q-input
          v-model="form.name.value"
          label="Название слова"
          outlined
          required
          :error="form.name.error != null"
          :error-message="form.name.error"
          maxlength="200"
        />

        <q-select
          v-model="form.categories.value"
          label="Отрасль"
          outlined
          use-input
          use-chips
          multiple
          :options="categoriesOptions"
          option-value="id"
          option-label="name"
          :error="form.categories.error != null"
          :error-message="form.categories.error"
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ '—'.repeat(scope.opt.level) }} {{ scope.opt.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="form.tags.value"
          label="Тег"
          outlined
          use-input
          use-chips
          multiple
          input-debounce="0"
          @new-value="createNewTag"
          :options="tagsOptions"
          @filter="filterTags"
          :error="form.tags.error != null"
          :error-message="form.tags.error"
        />

        <label>Краткое описание</label>
        <q-input
          v-model="form.teaser.value"
          outlined
          type="textarea"
          rows="2"
          :error="form.teaser.error != null"
          :error-message="form.teaser.error"
        />

        <label>Источник краткого описания</label>
        <q-editor
          v-model="form.teaserSource.value"
          outlined
          :definitions="form.teaserSource.definitions"
          :toolbar="form.teaserSource.toolbar"
          :error="form.teaserSource.error != null"
          :error-message="form.teaserSource.error"
          min-height="4rem"
        />

        <label>Полное описание</label>
        <q-editor
          v-model="form.description.value"
          ref="editor"
          :definitions="form.description.definitions"
          :toolbar="form.description.toolbar"
          label="Полное описание"
          outlined
          type="textarea"
          rows="6"
          :error="form.description.error != null"
          :error-message="form.description.error"
        >
          <template v-slot:image>
            <q-btn
              dense no-caps
              icon="image"
              size="sm"
            >
              <q-menu class="images-menu" ref="imagesMenu">
                <q-list>
                  <q-item
                    clickable
                    v-close-popup
                    v-for="file in termImages" :key="file.id"
                    @click="insertImage(file)"
                  >
                    <q-item-section avatar>
                      <q-avatar rounded>
                        <img :src="file.thumbnailUrl" alt="">
                      </q-avatar>
                    </q-item-section>
                    <q-item-section>
                      <div class="ellipsis">
                        {{ file.name }}
                      </div>
                    </q-item-section>
                  </q-item>
                  <q-item class="upload-image-item">
                    <q-file
                      v-model="form.newImage.value"
                      accept=".jpg,.png,.webp"
                      borderless
                      label="Загрузить изображение"
                      @update:model-value="uploadImage"
                      :loading="form.newImage.processing"
                    >
                      <template v-slot:prepend>
                        <q-icon name="add" />
                      </template>
                    </q-file>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </template>
        </q-editor>

        <label>Источник полного описания</label>
        <q-editor
          v-model="form.descriptionSource.value"
          outlined
          :definitions="form.teaserSource.definitions"
          :toolbar="form.descriptionSource.toolbar"
          :error="form.descriptionSource.error != null"
          :error-message="form.descriptionSource.error"
          min-height="4rem"
        />

        <div class="edit-audio">
          <div v-if="form.audio.value" class="audio">
            <q-btn @click="playAudio(form.audio.value)" class="audio--play">
              <q-icon>
                <img src="../../assets/icons/play-audio.svg" alt="">
              </q-icon>
            </q-btn>
            <div class="audio--name">
              {{ form.audio.value.name }}
            </div>
            <q-btn @click="form.audio.value = false" class="audio--delete">
              <q-icon>
                <img src="../../assets/icons/delete-audio.svg" alt="">
              </q-icon>
              Удалить
            </q-btn>
          </div>
          <div v-else>
            <q-file
              accept=".mp3"
              v-model="form.audio.file"
              @update:model-value="selectAudioFile"
              outlined
              :error="form.audio.error != null"
              :error-message="form.audio.error"
              class="audio--file"
            />
          </div>
        </div>

        <q-input
          v-model="form.typos.value"
          label="Варианты слова с опечатками"
          outlined
          :error="form.typos.error != null"
          :error-message="form.typos.error"
          maxlength="600"
        />

        <q-checkbox v-model="form.published.value" label="Опубликовать"/>

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as termsApi } from '../../api/terms.js'
import { api as categoriesApi } from '../../api/categories.js'
import { api as tagsApi } from '../../api/tags.js'
import api from '../../api/api.js'

const sourceToolbar = [
  ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
  ['token', 'link'],
  ['fullscreen'],
  ['quote', 'unordered', 'ordered'],
  ['undo', 'redo'],
  ['clear_styles'],
  ['viewsource']
]
const descriptionToolbar = [
  ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
  ['token', 'hr', 'link', 'image'],
  ['fullscreen'],
  ['quote', 'unordered', 'ordered'],
  ['undo', 'redo'],
  ['clear_styles'],
  ['viewsource'],
]
const audioPlayer = new Audio()

export default {
  props: {
    term: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: false,
    },
  },
  emits: ['ok'],
  data() {
    const definitions = {
      clear_styles: {
        tip: 'Очистить стили',
        icon: 'cleaning_services',
        handler: this.clearStyles
      },
    }

    return {
      ready: false,
      processing: false,
      error: null,

      form: {
        name: {
          value: '',
          error: null,
        },
        categories: {
          value: null,
          error: null,
        },
        tags: {
          value: null,
          error: null,
        },
        teaser: {
          value: '',
          error: null,
        },
        teaserSource: {
          value: '',
          toolbar: sourceToolbar,
          definitions: definitions,
          error: null,
        },
        description: {
          value: '',
          error: null,
          toolbar: descriptionToolbar,
          definitions: definitions,
        },
        descriptionSource: {
          value: '',
          toolbar: sourceToolbar,
          definitions: definitions,
          error: null,
        },
        audio: {
          value: '',
          removed: null,
          original: null,
          error: null,
          file: null,
        },
        typos: {
          value: '',
          error: null,
        },
        published: {
          value: false,
          error: null,
        },
        files: {
          value: [],
        },
        newImage: {
          value: null,
          processing : false,
        },
      },

      categories: [],
      categoriesOptions: [],
      tags: [],
      tagsOptions: [],
    }
  },
  async created() {
    const categories = await categoriesApi.findTree()
    const options = []
    const processLevel = categories => {
      categories.forEach(category => {
        options.push(category)
        processLevel(category.children)
      })
    }
    processLevel(categories)
    this.categoriesOptions = options

    const tags = await tagsApi.findBy({perPage: 1000})
    tags.forEach(tag => {
      this.tags.push(tag.name)
    })
    this.tagsOptions = this.tags

    const term = this.term.id
      ? await termsApi.find(this.term.id)
      : this.term
    this.initForm(term)

    this.ready = true
  },
  methods: {
    initForm(term) {
      this.form.name.value = term.name
      this.form.categories.value = term.categories ? term.categories : []
      this.form.tags.value = term.tags ? term.tags.map(tag => tag.name) : []
      this.form.teaser.value = term.teaser || ''
      this.form.teaserSource.value = term.teaserSource || ''
      this.form.description.value = term.description || ''
      this.form.descriptionSource.value = term.descriptionSource || ''
      this.form.audio.value = term.audio || ''
      this.form.typos.value = term.typos || ''
      this.form.published.value = term.published || false
      this.form.files.value = term.files || []
    },
    createNewTag(val, done) {
      if (val.length > 0) {
        const modelValue = (this.form.tags.value || []).slice()

        val
          .split(/[,;|]+/)
          .map(v => v.trim())
          .filter(v => v.length > 0)
          .forEach(v => {
            if (this.tags.includes(v) === false) {
              this.tags.push(v)
            }
            if (modelValue.includes(v) === false) {
              modelValue.push(v)
            }
          })

        done(null)
        this.form.tags.value = modelValue
      }
    },
    filterTags(val, update) {
      update(() => {
        if (val === '') {
          this.tagsOptions = this.tags
        }
        else {
          const needle = val.toLowerCase()
          this.tagsOptions = this.tags.filter(
            v => v.toLowerCase().indexOf(needle) > -1
          )
        }
      })
    },
    selectAudioFile() {
      this.form.audio.value = this.form.audio.file
      this.form.audio.file = null
    },
    playAudio(audio) {
      if (audioPlayer.paused) {
        audioPlayer.src = audio.url
        audioPlayer.play()
      } else {
        audioPlayer.pause()
      }
      audioPlayer.src = audio.url ? audio.url : URL.createObjectURL(audio)
      audioPlayer.play()
    },
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onCancelClick() {
      this.hide()
    },
    async uploadImage(fileData) {
      this.form.newImage.processing = true
      const file = await api.files.uploadFile(fileData)
      this.form.files.value.push(file)
      this.form.newImage.value = null
      this.form.newImage.processing = false
      this.insertImage(file)
      this.$refs.imagesMenu.hide()
    },
    insertImage(file) {
      const editor = this.$refs.editor
      editor.caret.restore()
      editor.runCmd('insertHTML', `<img src="https://${window.location.host}${file.url}" alt=""/>`)
      editor.focus()
    },
    clearStyles(e, q, caret) {
      caret.el.querySelectorAll('[style]').forEach(el => el.removeAttribute('style'))
      let newContent = caret.el.innerHTML
      newContent = newContent.replaceAll(/<font[^>]+>/ig, '')
      newContent = newContent.replaceAll('</font>', '')
      q.setContent('')
      q.runCmd('insertHTML', newContent)
    },
    onSubmit() {
      const term = termsApi.create()
      term.id = this.term.id
      term.name = this.form.name.value
      term.categories = this.form.categories.value.map(category => category.id)
      term.tags = this.form.tags.value
      term.teaser = this.form.teaser.value
      term.teaserSource = this.form.teaserSource.value
      term.description = this.form.description.value
      term.descriptionSource = this.form.descriptionSource.value
      if (this.form.audio.value !== null) {
        term.audio = this.form.audio.value
      }
      term.typos = this.form.typos.value
      term.published = this.form.published.value
      term.request = this.term.request || null
      term.files = this.form.files.value

      this.processing = true
      termsApi
        .save(term)
        .then(term => {
          this
            .hook(term)
            .then(() => {
              this.$emit('ok', term)
              this.hide()
            })
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              if (error.source) {
                switch (error.source) {
                  case 'title':
                    this.form.name.error = error.title
                    break
                  case 'subtitle':
                    this.form.teaser.error = error.title
                    break
                  case 'description':
                    this.form.description.error = error.title
                    break
                  case 'audio_file':
                    this.form.audio.error = error.title
                    break
                }
              } else {
                this.error = error.title
              }
            })
          }
        })
    },
  },
  computed: {
    dialogTitle() {
      return !this.term.id ? 'Новое слово' : 'Редактировать слово'
    },
    termImages() {
      return this.form.files.value.filter(file => file.type.includes('image'))
    },
  }
}
</script>

<style lang="scss">
.edit-term-dialog {
  .q-dialog__inner > .dialog-content {
    width: 966px;
    max-width: 966px;
  }
  .q-editor {
    margin-bottom: 20px;
  }
}

.edit-audio {
  margin-bottom: 20px;
}
.audio {
  display: flex;
  align-items: center;
  padding: 11px;
  min-height: 60px;
  background: rgba(44, 125, 220, 0.12);
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;

  &--play {
    padding: 5px;
    width: 40px;
    margin-right: 15px;
  }

  &--name {
    flex: 1;
  }

  &--delete {
    font-size: 14px;
    line-height: 16px;
    color: #F36565;
  }

  &--file {
    display: block;
    background: rgba(44, 125, 220, 0.12);
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    color: #2C7DDC;
    padding: 0;
    margin: 0;

    &.q-field--outlined .q-field__control::before {
      border: none;
    }

    .q-field__native::before {
      content: "+ Выбрать аудио";
      margin: auto;
      font-size: 16px;
      color: #2C7DDC;
    }

    input {
      display: none;
    }
  }
}
.images-menu {
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .ellipsis {
    width: 100%;
  }

  .upload-image-item {
    justify-content: center;
  }
  .q-file {
    width: 230px;
  }
}

.q-editor__content img {
  max-width: 100%;
}

</style>
